<template>
  <div class="login-container">
    <div class="login-content">
      <div class="login-panel">
        <loginForm />
      </div>
    </div>
    <!-- 底部 -->
    <div class="login_bottom">
      <div class="left">
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备2021019398号-2</a>
          ｜
          <span @click="showModal = true">营业执照</span>
        </p>
        <span>copyright © 2021-2022 北京值元数字科技有限公司. All rights reserved. 版权所有 本站内容未经书面许可,禁止一切形式的转载。
        </span>
      </div>
      <div class="right">
        <span><i class="iconfont icon-denglu_dianhua"></i> 联系方式</span>
        <span>电话：010-56640807</span>
        <span>邮箱：zhishu@zhidemai.com</span>
      </div>
    </div>
  </div>
  <el-dialog v-model="showModal" title="营业执照" custom-class="login-bottom" width="500px">
    <img src="@/assets/business/business.jpg" alt="">
  </el-dialog>
</template>

<script>
import { defineComponent, ref } from 'vue'
import loginForm from '@/components/Login/login-form.vue'

export default defineComponent({
  name: 'Login',
  components: {
    loginForm
  },
  setup () {
    const showModal = ref(false)
    return {
      showModal
    }
  }
})
</script>
<style lang="scss" scoped>
.login-container {
  width: 100vw !important;
  height: 100vh;
  margin-left: 0 !important;
  background-color: #f1f1f1;
  overflow-y: auto;

  .login-content {
    position: relative;
    height: 100%;
    min-height: 750px;
    // height: calc(100% - 110px);
    background-image: url('../assets/background/bg-login@2.5x.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    .login-panel {
      padding: 48px;
      position: absolute;
      top: 18%;
      right: 100px;
      width: 364px;
      // height: 393px;
      background-color: #fff;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, .04);
    }
  }

  .login_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 112px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFangSC-Regular;

    .left {
      padding: 0 32px;
      // width: 1227px;
      flex: 1;
      height: 72px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      span, p {
        color: #84868C;
        font-size: 12px;
        span {
          cursor: pointer;
        }
      }
      a {
        color: #84868C;
        text-decoration:none;
      }
    }

    .right {
      padding: 0 32px;
      width: 212px;
      height: 72px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      border-left: 1px solid #E9ECF2;

      span:nth-child(1) {
        display: flex;
        align-items: center;

        i {
          color: #84868C;
          margin-right: 4px;
        }
      }

      span {
        color: #84868C;
        font-size: 12px;
      }
    }
  }
}
</style>
<style lang="scss">
.login-form {
  .el-form-item__content {
    margin-left: 0 !important;
  }
}
.login-bottom {
  .el-dialog__header {
    display: flex;
    justify-content: space-between;
  }
  .el-dialog__body {
    padding-top: 0 !important;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
