<template>
  <div class="login-panel-form" v-show="state.control[0]">
    <div class="login-panel-header"></div>
    <h1>账户登录</h1>
    <el-form class="login-form" :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px">
      <el-form-item prop="username" class="marginTop-32">
        <el-input prefix-icon="iconfont icon-denglu_zhanghao" clearable v-model="ruleForm.username" placeholder="请输入用户名称/手机号"></el-input>
      </el-form-item>
      <el-form-item prop="password" class="marginTop-28">
        <el-input prefix-icon="iconfont icon-mima" clearable show-password v-model="ruleForm.password" placeholder="请输入用户密码"></el-input>
      </el-form-item>
      <el-form-item class="marginTop-100" width="100%">
        <el-button type="primary" class="login-submit" @keyup.enter="submitForm" @click="submitForm">登录</el-button>
      </el-form-item>
      <div class="login-button">
        <el-popconfirm title="如果您需要申请账号，请致电：(010)56640807" popper-class="popconfirm">
          <template #reference>
            <el-button class="login-button-text" type="text">账号申请</el-button>
          </template>
        </el-popconfirm>
        <el-button @click="loginPanelFormGoback(2)" class="login-button-text" type="text">忘记密码</el-button>
      </div>
    </el-form>
  </div>
  <div class="login-panel-form" v-show="state.control[1]">
    <div class="login-panel-header"></div>
    <h1>找回密码</h1>
    <el-form class="login-form" :model="findForm" :rules="findRules" ref="findFormRef" label-width="100px">
      <el-form-item prop="phoneNumber" class="marginTop-32">
        <el-input v-model="findForm.phoneNumber" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item prop="valiCode" class="marginTop-28">
        <el-row>
          <el-col :span="15">
        <el-input v-model="findForm.valiCode" placeholder="请输入验证码">
        </el-input>
          </el-col>
          <el-col :span="8">
            <el-button class="get-vali-code" :style="`padding-left: ${isClickedGetValiCode ? '13' : '20'}px;`" @click="getValiCode" :class="disable ? 'disable' : ''" :disabled="disable">{{ valiCodeBtn }}</el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item class="marginTop-100" width="100%">
        <el-button type="primary" class="login-submit" @click="submitFindForm">下一步</el-button>
        <span @click="loginPanelFormGoback(1)" class="go-back"><i class="el-icon-arrow-left"></i>返回</span>
      </el-form-item>
    </el-form>
  </div>
  <div class="login-panel-form" v-show="state.control[2]">
    <div class="login-panel-header"></div>
    <h1>重置密码</h1>
    <el-form class="login-form" :model="resetForm" :rules="resetRules" ref="resetFormRef" label-width="100px">
      <el-form-item prop="newPassword" class="marginTop-32">
        <el-input v-model="resetForm.newPassword" placeholder="请输入新密码" show-password></el-input>
      </el-form-item>
      <el-form-item prop="confirmPassword" class="marginTop-30">
        <el-input class="marginTop-28" v-model="resetForm.confirmPassword" placeholder="请再输入一遍新密码" show-password></el-input>
      </el-form-item>
      <el-form-item class="marginTop-100" width="100%">
        <el-button type="primary" class="login-submit" @click="submitResetForm">去登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { findPriority, getQueryVariable } from '@/utils/index'
import { loginLog } from '@/utils/saveLog'

export default defineComponent({
  setup () {
    const router = useRouter()
    const store = useStore()

    // 获取DOM refs
    const ruleFormRef = ref(null)
    const resetFormRef = ref(null)
    const findFormRef = ref(null)

    // 控制登录、找回、重置面板的展示
    const state = reactive({
      control: [true, false, false]
    })

    const ruleForm = reactive({
      username: '',
      password: ''
    })

    const rules = reactive({
      username: [
        { required: true, message: '用户名/手机号不能为空', trigger: 'blur' },
        { min: 5, max: 18, message: '长度在 5 到 18 个字符', trigger: 'blur' }
      ],
      password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
        { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
      ]
    })

    const findForm = reactive({
      phoneNumber: '',
      valiCode: ''
    })

    const findRules = reactive({
      phoneNumber: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { min: 11, max: 11, message: '长度为 11 个数字', trigger: 'blur' }
      ],
      valiCode: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        { min: 4, max: 6, message: '长度为 6 个数字', trigger: 'blur' }
      ]
    })

    const resetForm = reactive({
      newPassword: '',
      confirmPassword: ''
    })

    // 确认新密码的验证规则
    const confirmVali = (rule, value, callback) => {
      if (resetForm.newPassword !== resetForm.confirmPassword) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }

    const resetRules = reactive({
      newPassword: [
        { required: true, message: '请输入新密码', trigger: 'blur' },
        { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' },
        { pattern: /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{6,15}$/, message: '密码格式为数字、符号、字母任意两项，长度为6-15位' }

      ],
      confirmPassword: [
        { required: true, message: '请确认新密码', trigger: 'blur' },
        { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' },
        { validator: confirmVali, trigger: 'blur' }
      ]
    })

    // 获取验证码的倒计时
    const wait = ref(60)
    const disable = ref(false)
    const valiCodeBtn = ref('获取验证码')
    const isClickedGetValiCode = ref(false)

    const getValiCode = () => {
      findFormRef.value.validateField('phoneNumber', msg => {
        if (!findForm.phoneNumber) return

        store.dispatch('getValidateCode', {
          phone: findForm.phoneNumber,
          action: 'retrieve_pass'
        }).then(res => {
          const timer = setInterval(() => {
            wait.value--
            if (wait.value <= 0) {
              valiCodeBtn.value = '获取验证码'
              disable.value = false
              isClickedGetValiCode.value = false
              wait.value = 60
              clearInterval(timer)
            } else {
              valiCodeBtn.value = `重发验证码(${wait.value})`
              disable.value = true
              isClickedGetValiCode.value = true
            }
          }, 1000)
        })
      })
    }

    // 找回密码流程
    const loginPanelFormGoback = path => {
      if (path === 1) {
        // 清空输入框的密码
        clearFields(ruleFormRef)

        // 如果找回密码时填写了手机号 则在跳转回登录时 带出手机号
        if (!isNaN(+findForm.phoneNumber)) {
          ruleForm.username = findForm.phoneNumber
        }

        state.control = [true, false, false]
      } else {
        // 清空输入框的密码
        clearFields(findFormRef)

        // 如果用户名填写了手机号 则在跳转到找回密码时 带出手机号
        if (!isNaN(+ruleForm.username)) {
          findForm.phoneNumber = ruleForm.username
        }

        state.control = [false, true, false]
      }
    }

    // 重置表单
    const clearFields = formName => {
      formName.value.resetFields()
    }

    // 提交登录
    const submitForm = () => {
      ruleFormRef.value.validate(valid => {
        if (valid) {
          login()
        } else {
          return false
        }
      })
    }
    const login = () => {
      // 改变登录状态后 清空表单输入框 并跳转至首页
      store.dispatch('login', { user: ruleForm }).then(res => {
        store.dispatch('getInfo').then(user => {
          clearFields(ruleFormRef)
          // 根据权限查找优先级
          const path = findPriority(store.state.permission)
          router.push(path)
          loginLog(1)
        })
      })
    }

    // url中有code直接登录
    if (getQueryVariable('access_token')) {
      store.dispatch('getSSOToken').then(res => {
        store.dispatch('getInfo').then(user => {
          clearFields(ruleFormRef)
          // 根据权限查找优先级
          const path = findPriority(store.state.permission)
          router.replace(path)
          loginLog(16)
        })
      })
    }

    // 提交找回密码
    const submitFindForm = () => {
      findFormRef.value.validate(valid => {
        if (valid) {
          store.dispatch('validateCode', {
            code: findForm.valiCode,
            phone: findForm.phoneNumber
          }).then(res => {
            clearFields(resetFormRef)
            state.control = [false, false, true]
          })

          // 清空输入框的密码
          resetForm.newPassword = ''
          resetForm.confirmPassword = ''
        } else {
          return false
        }
      })
    }

    // 提交重置密码
    const submitResetForm = () => {
      resetFormRef.value.validate(valid => {
        if (valid) {
          store.dispatch('resetPassword', {
            password: resetForm.newPassword
          }).then(msg => {
            ElMessage({
              message: msg,
              type: 'success',
              duration: 2 * 1000
            })
            clearFields(ruleFormRef)
            state.control = [true, false, false]
          })
        } else {
          return false
        }
      })
    }

    return {
      getValiCode,
      valiCodeBtn,
      disable,
      state,
      findForm,
      ruleForm,
      rules,
      findRules,
      resetForm,
      resetRules,
      loginPanelFormGoback,
      clearFields,
      ruleFormRef,
      findFormRef,
      resetFormRef,
      submitForm,
      submitFindForm,
      submitResetForm,
      isClickedGetValiCode
    }
  }
})
</script>

<style lang="scss" scoped>
.login-panel-form {
  .login-panel-header {
    width: 20px;
    height: 4px;
    background-color: #f04848;
  }

  .get-vali-code {
    width: 100%;
    height: 100%;
    margin-left: 14px;

    span {
      font-family: PingFangSC-Regular;
    }
  }

  .go-back {
    display: block;
    line-height: 20px;
    margin-top: 20px;
    color: #435B8D;
    cursor: pointer;

    i {
      color: #435B8D;
      margin-right: 4px;
    }
  }

  & > h1 {
    font-family: PingFangSC-Medium;
    margin-top: 12px;
    font-size: 24px;
    color: #242426;
    line-height: 33px;
    text-align: left;
  }
}

.login-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.disable {
  color: #909399 !important;
  cursor: not-allowed !important;
  background-image: none !important;
  border-color: #DCDFE6 !important;

  &:hover {
    background-color: #F5F7FA !important;
    border: 1px solid #DCDFE6 !important;
  }
}

.login-submit {
  width: 100%;
  height: 48px;
}

.marginTop-32 {
  margin-top: 32px;
}

.marginTop-28 {
  margin-top: 28px;
}

.marginTop-100 {
  margin-top: 100px;
}
</style>

<style lang="scss">
.popconfirm {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .el-popconfirm__action {
    display: none;
  }
  .el-popper__arrow::before {
    border: 0 !important;
  }
}

.login-form {
  overflow: hidden;

  .el-input__inner {
    height: 48px;
    border-radius: 2px;
    border-color: #D0D3D9;
  }

  .el-input__icon {
    height: 100%;
    font-size: 16px;
    margin-left: 8px;
    color: #84868C;
  }

  .el-input--prefix .el-input__inner {
    padding-left: 36px;
  }

  .el-form-item__error {
    line-height: 17px;
  }

  .el-button--danger {
    height: 48px;
    border-radius: 2px;
    border-color: #f04848;
    background-color: #f04848;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .login-submit span {
     color: #fff;
  }

  .login-button {
    margin-top: 20px;

    .el-button {
      font-family: PingFangSC-Regular;
      padding: 0;
      font-size: 14px;
      min-height: 20px;
      line-height: 20px;
      span {
        color: #435B8D;
      }
    }
  }

  .get-vali-code.el-button--default {
    span {
      color: #84868C;
    }

    &:hover {
      span, i {
        color: #F04848 !important;
      }
    }
  }

  .disable.el-button--default {
    span {
      color: #84868C;
    }

    &:hover {
      span, i {
        color: #84868C !important;
      }
    }
  }
}
</style>
